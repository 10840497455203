

:root {
  --primary: white;
  --secondary: #0A213A;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 14px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 5px solid var(--primary);
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}