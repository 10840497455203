@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* :root {
    --color-primary: 18 35 56;
  } */
  html, body {
    font-family: Roboto Flex;
    height: 100%;
  }
}