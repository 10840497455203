/*
 |--------------------------------------------------------------------------
 | TailwindCSS Directives
 |--------------------------------------------------------------------------
 |
 | Import TailwindCSS directives and swipe out at build-time with all of
 | the styles it generates based on your configured design system.
 |
 | Please check this link for more details:
 | https://tailwindcss.com/docs/installation#include-tailwind-in-your-css
 |
 */
 @import "./_tailwind.css";

 /*
    |--------------------------------------------------------------------------
    | Fonts
    |--------------------------------------------------------------------------
    |
    | Import all fonts used in the template, the font configuration can be
    | seen in "tailwind.config.js".
    |
    | Please check this link for more details:
    | https://tailwindcss.com/docs/theme
    |
    */
 @import "./_roboto.css";

 /*
   |--------------------------------------------------------------------------
   | Components
   |--------------------------------------------------------------------------
   |
   | Import CSS components.
   |
   */
@import "./components/_scroll.css";
 
#root {
   height: 100%
 }

.spin-button-none::-webkit-outer-spin-button,
.spin-button-none::-webkit-inner-spin-button {
  appearance: none;
}

.position-x {
   background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - .5em) .5em;
 }

 .second-line-ellipsis {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
 }

 .h-revert {
  height: revert;
 }